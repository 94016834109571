import React from "react";

export default function ErrorFormMessage({
  message,
}: {
  message: React.ReactElement | boolean;
}) {
  if (!message) {
    return null;
  }
  return <div className="text-red-500 mt-2 text-sm">{message}</div>;
}
