import React from "react";

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  type?: "button" | "submit" | "reset";
  theme?: string;
  disabled?: boolean;
  size?: string;
  cssCLasses?: string;
};

export default function Button({
  onClick,
  title,
  theme,
  type = "button",
  disabled,
  size,
  cssCLasses = "",
}: Props) {
  let themeClasses =
    "text-dark-blue bg-white border-white disabled:bg-gray-400 disabled:border-gray-400";
  let sizeClasses = "text-[14px] py-4 px-5";

  if (theme === "white-transparent") {
    themeClasses = "text-white border-white";
  }
  if (theme === "dark-transparent") {
    themeClasses = "text-dark-blue border-dark-blue";
  }
  if (theme === "dark") {
    themeClasses = "text-white bg-dark-blue border-dark-blue";
  }
  if (theme === "yellow-transparent") {
    themeClasses = "text-dark-yellow border-dark-yellow";
  }
  if (size === "small") {
    sizeClasses =
      "text-[10px] lg:text-[12px] border-[1px] lg:border-[2px] py-2 px-3";
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`border-[2px] font-interbold uppercase ${sizeClasses} rounded-[25px] leading-none ${themeClasses} ${cssCLasses}`}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
