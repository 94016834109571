import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const closeIcon = new URL(
  "../assets/images/icon-close-dark.svg",
  import.meta.url
);

type AppProps = {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
};

const Modal = ({ title, children, onClose }: AppProps) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    const modalRoot = document.getElementById("modal");
    if (!modalRoot || !elRef.current) {
      return;
    }
    modalRoot.appendChild(elRef.current);
    return () => {
      if (elRef.current) {
        modalRoot.removeChild(elRef.current);
      }
    };
  }, []);

  return createPortal(
    <div
      className="fixed z-50 flex w-full h-full justify-center items-start h-full"
      style={{ backgroundColor: "rgba(0, 39, 51, .5)" }}
    >
      <div className="flex justify-center pt-[20vh] max-h-[90%] w-full">
        <div className="flex flex-col max-w-[90%] w-auto p-5 lg:p-7 mx-5 bg-[#fafbfb] rounded-xl relative drop-shadow-xl">
          <div className="self-end mb-1">
            <img
              src={closeIcon.href}
              alt="close"
              width="20"
              height="20"
              className="h-fit max-w-none cursor-pointer"
              onClick={onClose}
            />
          </div>
          {title ? (
            <h1 className="mb-6 text-center font-pprightgothic text-3xl sm:text-4xl md:text-5xl text-dark-blue px-8">
              {title}
            </h1>
          ) : null}
          <div className="overflow-auto">{children}</div>
        </div>
      </div>
    </div>,
    elRef.current
  );
};

export default Modal;
