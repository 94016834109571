import React from "react";

import LoaderIcon from "./LoaderIcon";

export default function LoaderScreen() {
  return (
    <div className="absolute flex top-0 left-0 h-screen w-full justify-center items-center bg-[rgba(0,39,51,0.7)]">
      <LoaderIcon />
    </div>
  );
}
