import React, { useEffect, useState } from "react";

import { downloadContract, submitContract } from "../services/ida";
import { generateRandomString, isFieldValid } from "../services/utils";
import {
  AnswersLog,
  ContractSubmit,
  ContractSubmitResponse,
  ErrorFields,
} from "../types/entities";
import Button from "./Button";
import ErrorFormMessage from "./ErrorFormMessage";
import HelpMessage from "./HelpMessage";
import Input from "./Input";
import LoaderScreen from "./LoaderScreen";

export default function ContactInfo({
  contactInfo,
  setContactInfo,
  otherInfo,
  setOtherInfo,
  onSubmit,
}) {
  const [firstname, setFirstname] = useState("");
  const [otherFirstname, setOtherFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [otherLastname, setOtherLastname] = useState("");
  const [email, setEmail] = useState("");
  const [otherEmail, setOtherEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [person, setPerson] = useState();
  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const [submitting, setSubmitting] = useState(false);
  const [liveValidation, setLiveValidation] = useState(false);

  useEffect(() => {
    if (!contactInfo) {
      return;
    }
    setFirstname(contactInfo.firstname);
    setLastname(contactInfo.lastname);
    setEmail(contactInfo.email);
    setPhone(contactInfo.phone);
    setPerson(contactInfo.person);
  }, []);

  useEffect(() => {
    if (!otherInfo) {
      return;
    }
    setOtherFirstname(otherInfo.firstname);
    setOtherLastname(otherInfo.lastname);
    setOtherEmail(otherInfo.email);
    setOtherPhone(otherInfo.phone);
  }, []);

  const validateData = (id = "", val = "", type = "") => {
    const errors = {} as ErrorFields;

    if (id && val) {
      errors[id] = isFieldValid(val, type);
    } else {
      errors["firstname"] = isFieldValid(firstname, "text");
      errors["lastname"] = isFieldValid(lastname, "text");
      errors["email"] = isFieldValid(email, "email");
      errors["phone"] = isFieldValid(phone, "phone");
      errors["person"] = isFieldValid(person, "radio");

      errors["otherFirstname"] = isFieldValid(otherFirstname, "text");
      errors["otherLastname"] = isFieldValid(otherLastname, "text");
      errors["otherEmail"] = isFieldValid(otherEmail, "email");
      errors["otherPhone"] = isFieldValid(otherPhone, "phone");
    }

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const onSubmitContract = () => {
    setLiveValidation(true);
    const res = validateData();
    if (Object.entries(res).length > 0) {
      return;
    }

    setContactInfo({
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      person: person,
    });

    setOtherInfo({
      firstname: otherFirstname,
      lastname: otherLastname,
      email: otherEmail,
      phone: otherPhone,
    });
    onSubmit();
  };

  const renderMainContacts = () => {
    if (!person) {
      return null;
    }
    return (
      <>
        <div
          className="font-interbold text-dark-blue bottom"
          style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
        >
          Your details
          <hr className="mt-1 mb-1" />
        </div>
        <div>
          <Input
            type="text"
            name="firstname"
            onChange={(e) => {
              setContactInfo();
              setFirstname(e.target.value);
              if (liveValidation) {
                validateData("firstname", e.target.value, "text");
              }
            }}
            label="Förnamn"
            placeholder="Fyll i ditt förnamn. Minst två tecken."
            value={firstname}
            required={true}
            error={errorFields["firstname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="text"
            name="lastname"
            onChange={(e) => {
              setContactInfo();
              setLastname(e.target.value);
              if (liveValidation) {
                validateData("lastname", e.target.value, "text");
              }
            }}
            label="Efternamn"
            placeholder="Fyll i ditt efternamn. Minst två tecken."
            value={lastname}
            required={true}
            error={errorFields["lastname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="email"
            name="email"
            onChange={(e) => {
              setContactInfo();
              setEmail(e.target.value);
              if (liveValidation) {
                validateData("email", e.target.value, "email");
              }
            }}
            label="E-post"
            placeholder="Fyll i din e-postadress"
            value={email}
            required={true}
            error={errorFields["email"] ?? false}
          />
        </div>
        <div>
          <Input
            type="tel"
            name="phone"
            onChange={(e) => {
              setContactInfo();
              setPhone(e.target.value);
              if (liveValidation) {
                validateData("phone", e.target.value, "phone");
              }
            }}
            label="Telefonnummer"
            placeholder="Fyll i ditt telefonnummer"
            value={phone}
            required={true}
            error={errorFields["phone"] ?? false}
          />
        </div>
      </>
    );
  };

  const renderOtherContacts = () => {
    if (!person) {
      return null;
    }
    let otherTitle = "Trusted person details";
    if (person === "2") {
      otherTitle = "Person details you are planning for";
    }
    return (
      <>
        <div
          className="font-interbold text-dark-blue bottom mt-3"
          style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
        >
          {otherTitle}
          <hr className="mt-1 mb-1" />
        </div>
        <div>
          <Input
            type="text"
            name="otherFirstname"
            onChange={(e) => {
              setContactInfo();
              setOtherFirstname(e.target.value);
              if (liveValidation) {
                validateData("otherFirstname", e.target.value, "text");
              }
            }}
            label="Förnamn"
            placeholder="Fyll i ditt förnamn. Minst två tecken."
            value={otherFirstname}
            required={true}
            error={errorFields["otherFirstname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="text"
            name="otherLastname"
            onChange={(e) => {
              setContactInfo();
              setOtherLastname(e.target.value);
              if (liveValidation) {
                validateData("otherLastname", e.target.value, "text");
              }
            }}
            label="Efternamn"
            placeholder="Fyll i ditt efternamn. Minst två tecken."
            value={otherLastname}
            required={true}
            error={errorFields["otherLastname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="email"
            name="otherEmail"
            onChange={(e) => {
              setContactInfo();
              setOtherEmail(e.target.value);
              if (liveValidation) {
                validateData("otherEmail", e.target.value, "email");
              }
            }}
            label="E-post"
            placeholder="Fyll i din e-postadress"
            value={otherEmail}
            required={true}
            error={errorFields["otherEmail"] ?? false}
          />
        </div>
        <div>
          <Input
            type="tel"
            name="otherPhone"
            onChange={(e) => {
              setContactInfo();
              setOtherPhone(e.target.value);
              if (liveValidation) {
                validateData("otherPhone", e.target.value, "phone");
              }
            }}
            label="Telefonnummer"
            placeholder="Fyll i ditt telefonnummer"
            value={otherPhone}
            required={true}
            error={errorFields["otherPhone"] ?? false}
          />
        </div>
      </>
    );
  };

  return (
    <div className="relative shadow-default bg-white rounded-xl px-3 py-4 md:px-7 md:py-6 mb-5">
      <div className="flex flex-row items-center flex-1 justify-between">
        <h1 className="font-pprightgothic text-[24px] sm:text-[34px] leading-none text-dark-blue max-w-full lg:max-w-3xl">
          Dina uppgifter
        </h1>
      </div>
      <div className="pt-3 max-w-full flex flex-col lg:flex-row justify-between gap-5">
        <div className="w-full lg:max-w-2xl">
          <form className="grid gap-4 grid-cols-1 sm:grid-cols-2">
            <div
              className="mb-2"
              style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
            >
              <div className="text-dark-blue font-interbold font-medium mb-2">
                Are you planning for yourself or someone else?{" "}
                <span className="text-red-700">*</span>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <div className="flex items-center gap-2">
                    <input
                      id="person-0"
                      type="radio"
                      value="1"
                      name="person"
                      onChange={(e) => {
                        setContactInfo();
                        setPerson(e.target.value);
                        if (liveValidation) {
                          validateData("person", e.target.value, "radio");
                        }
                      }}
                      checked={person === "1"}
                      className={`w-4 h-4 text-dark-blue bg-[#FFFFFFb0] border-dark-blue border-1 cursor-pointer focus:ring-2`}
                    />
                    <label
                      htmlFor="person-0"
                      className="text-sm text-dark-blue cursor-pointer leading-tight"
                    >
                      Me
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <input
                      id="person-1"
                      type="radio"
                      value="2"
                      name="person"
                      onChange={(e) => {
                        setContactInfo();
                        setPerson(e.target.value);
                        if (liveValidation) {
                          validateData("person", e.target.value, "radio");
                        }
                      }}
                      checked={person === "2"}
                      className={`w-4 h-4 text-dark-blue bg-[#FFFFFFb0] border-dark-blue border-1 cursor-pointer focus:ring-2`}
                    />
                    <label
                      htmlFor="person-1"
                      className="text-sm text-dark-blue cursor-pointer leading-tight"
                    >
                      Someone else
                    </label>
                  </div>
                </div>
              </div>
              {errorFields["person"] ? (
                <ErrorFormMessage message={errorFields["person"]} />
              ) : null}
            </div>

            {renderMainContacts()}
            {renderOtherContacts()}

            <div className="mt-3 flex gap-5">
              <Button
                theme="dark"
                title="Nästa"
                onClick={onSubmitContract}
                disabled={submitting}
              />
            </div>
          </form>
        </div>
        <div className="w-full md:max-w-[300px]">
          <HelpMessage type="contact" />
        </div>
      </div>
    </div>
  );
}
