import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { questionsData, sectionsData } from "../services/questions";
import { dataDeepCopy } from "../services/utils";
import Button from "./Button";
import ContactInfo from "./ContactInfo";
import CtmPhone from "./CtmPhone";
import Input from "./Input";
import Modal from "./Modal";
import ProgressSections from "./ProgressSections";
import Question from "./Question";

export default function QuestionList({
  answersLog,
  updateAnswersLog,
  contactInfo,
  setContactInfo,
  submitAnswers,
  otherInfo,
  setOtherInfo,
}) {
  const [activeSection, setActiveSection] = useState(1);
  const [questions, setQuestions] = useState();
  const [sections, setSections] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState();

  const [debug] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const currentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setQuestions(questionsData());
    setSections(sectionsData());
  }, []);

  useEffect(() => {
    if (activeSection === 6) {
      return;
    }
    if (!questions || activeSection === 1) {
      return;
    }
    const questionList = questions.filter(
      (el) => el.section === activeSection && el.completed === false
    );
    if (questionList.length === 0) {
      updateSectionCompleted(activeSection, true);
      setActiveSection(activeSection + 1);
    }
  }, [questions]);

  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    let topOffset = 0;
    if (scrollRef.current) {
      topOffset = scrollRef.current?.offsetTop;
    }

    window.scrollTo({
      top: topOffset,
      left: 0,
      behavior: "smooth",
    });
  }, [answersLog]);

  const switchSections = (sectionId) => {
    if (sections[0].completed === false) {
      return;
    }

    setActiveSection(sectionId);
  };

  const updateSectionCompleted = (sectionId, isCompleted) => {
    const s = [...sections];
    s.map((el) => {
      if (el.id === sectionId) {
        el.completed = isCompleted;
      }
    });
    setSections(s);
  };

  const renderAnsweredQuestions = () => {
    const totalQuiestionList = questions.filter(
      (el) => el.section === activeSection
    );
    const questionList = questions.filter(
      (el) => el.section === activeSection && el.completed === true
    );
    if (questionList.length === 0) {
      return;
    }

    const showLabel = totalQuiestionList.length !== questionList.length;
    const questionsRender = questionList.map((el, index) => {
      return (
        <div
          className={
            !showLabel && index + 1 === questionList.length ? "" : "mb-5"
          }
          key={el.id}
        >
          <Question
            question={el}
            submitQuestionData={updateAnswersLog}
            setQuestionCompleted={setQuestionCompleted}
            answers={answersLog}
            openOnLoad={false}
            index={index}
          />
        </div>
      );
    });

    return (
      <div className="relative">
        <div className="w-1 h-full absolute bg-light-yellow top-0 left-[30px]" />
        {showLabel && (
          <div className="w-1 absolute bg-gradient-to-t from-white to-light-yellow bottom-0 left-[30px] h-[40] z-[1]" />
        )}
        {questionsRender}
        <div ref={scrollRef} />
        {showLabel && (
          <div className="relative text-light-yellow font-interbold mb-5 flex flex-row pl-[25] z-[2] leading-none">
            <div className="h-[14] w-[14] bg-light-yellow rounded-full mr-4" />
            <span>{t("finished")}</span>
          </div>
        )}
      </div>
    );
  };

  const renderQuestionsForActiveSection = () => {
    const questionList = questions.filter(
      (el) => el.section === activeSection && el.completed === false
    );

    if (questionList.length === 0) {
      return;
    }

    const questionsRender = questionList.map((el, index) => {
      return (
        <div className="mb-5 last:mb-0" key={`${el.id}-${index}`}>
          <Question
            question={el}
            submitQuestionData={updateAnswersLog}
            setQuestionCompleted={setQuestionCompleted}
            answers={answersLog}
            openOnLoad={index === 0}
            index={index}
          />
        </div>
      );
    });
    return (
      <div>
        <div className="relative text-white font-interbold mb-5 flex flex-row pl-[25] z-[2] leading-none">
          <div className="h-[14] w-[14] bg-white rounded-full mr-4" />
          <span>{t("in-progress")}</span>
        </div>
        <div className="w-1 h-full absolute bg-white top-0 left-[30px]" />
        {questionsRender}
      </div>
    );
  };

  const renderSummary = () => {
    const renderQuestions = questions.map((el) => {
      if (answersLog[el.name] === undefined) {
        return null;
      }

      const showNested = el.options?.filter((elm) => {
        return elm.value === answersLog[el.name] && elm.nested === true;
      });

      const renderSetAnswers = (data) => {
        const out = [];
        for (const [key, value] of Object.entries(data)) {
          const el = (
            <div>
              <span className="font-interbold">
                {value.name}, {value.tel}
              </span>
            </div>
          );
          out.push(el);
        }

        return <>{out}</>;
      };

      const renderTitle = (el, title) => {
        return (
          <div className="flex flex-row items-center gap-2 mb-2 justify-between lg:justify-start">
            <h1 className="font-pprightgothic text-[24px] leading-none text-dark-blue">
              {title}
            </h1>
            <Button
              onClick={() => {
                setModalQuestion(el);
                setShowModal(true);
              }}
              title="Ändra"
              size="small"
              theme="yellow-transparent"
            />
          </div>
        );
      };

      return (
        <>
          <div key={el.id} className="mb-5">
            {el.options &&
              el.field !== "set" &&
              el.options?.map((opt) => {
                if (opt.value === answersLog[el.name] && !opt.skipSummary) {
                  return (
                    <div>
                      {renderTitle(el, el.title)}
                      <span className="font-interbold">{opt.label}</span>
                    </div>
                  );
                }
              })}
            {!el.options && el.field !== "set" && (
              <>
                {renderTitle(el, el.title)}
                <span className="font-interbold">{answersLog[el.name]}</span>
              </>
            )}
            {el.field === "set" && (
              <>
                {renderTitle(el, el.title)}
                {renderSetAnswers(answersLog[el.name])}
              </>
            )}
          </div>
          {showNested &&
            el.children?.map((ch) => {
              if (
                answersLog[ch.name] !== undefined &&
                answersLog[ch.name] !== ""
              ) {
                return (
                  <div key={ch.id} className="mb-5">
                    {renderTitle(el, ch.title !== "" ? ch.title : el.title)}
                    <span className="font-interbold">
                      {answersLog[ch.name]}
                    </span>
                  </div>
                );
              }
            })}
        </>
      );
    });

    return (
      <div className="relative shadow-default bg-white rounded-xl px-3 py-4 md:px-7 md:py-6 mb-5">
        <h1 className="font-pprightgothic text-[28px] leading-none text-dark-blue mb-3">
          Alla frågor i förberedelseformuläret nu besvarade
        </h1>
        <div className="mb-4">
          Läs igenom dina svar i sammanställningen nedan. Är det något du vill
          ändra?
        </div>
        <div className="flex gap-5 mb-7">
          <Button
            title={t("complete-btn")}
            onClick={() => submitAnswers()}
            theme="dark"
          />
        </div>
        <hr className="pb-2 mb-5" />
        <div className="pt-3 max-w-full flex flex-col lg:flex-row justify-between gap-5">
          <div className="w-full lg:max-w-2xl">{renderQuestions}</div>
        </div>
      </div>
    );
  };

  const setQuestionCompleted = (q) => {
    const questionList = JSON.parse(JSON.stringify(questions));
    questionList.map((el) => {
      if (el.id === q.id) {
        el.completed = true;
      }
    });
    setQuestions(questionList);
  };

  return (
    <>
      {showModal && (
        <Modal title="" onClose={() => setShowModal(false)}>
          <Question
            question={modalQuestion}
            submitQuestionData={updateAnswersLog}
            setQuestionCompleted={setQuestionCompleted}
            answers={answersLog}
            modalMode={true}
            modalClose={() => {
              setShowModal(false);
            }}
            openOnLoad={true}
          />
        </Modal>
      )}
      <div className="mb-5">
        <ProgressSections
          sections={sections}
          activeSection={activeSection}
          setActive={switchSections}
        />
      </div>
      <div
        className="overflow-y-auto px-1 flex-grow flex flex-col"
        ref={wrapperRef}
      >
        {activeSection !== 1 && renderAnsweredQuestions()}

        <div className="relative flex-grow pb-20" ref={currentRef}>
          {activeSection === 1 && (
            <ContactInfo
              onSubmit={() => {
                updateSectionCompleted(activeSection, true);
                setActiveSection(activeSection + 1);
              }}
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
              otherInfo={otherInfo}
              setOtherInfo={setOtherInfo}
            />
          )}

          {activeSection !== 1 &&
            activeSection !== 6 &&
            renderQuestionsForActiveSection()}

          {activeSection === 6 && renderSummary()}
        </div>

        {debug && (
          <div className="text-white bg-dark-blue p-10 rounded-xl">
            <p className="font-bold mt-10">Debug info</p>
            <hr />

            <h1 className="text-sm font-bold my-3">Answers</h1>
            {Object.keys(answersLog).length > 0 ? (
              <pre className="text-[11px] overflow-auto w-full border">
                {JSON.stringify(answersLog, null, 2)}
              </pre>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
