import React from "react";

export default function Radio({ question, data, setData }) {
  const submitData = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const dt = { ...data };
    dt[name] = value;
    setData(dt);
  };

  return (
    <div className="">
      <div className="mb-5 px-1">
        {question.options.map((el) => {
          return (
            <div key={el.value} className="flex items-center gap-2 mb-2">
              <input
                id={`${question.name}-${el.value}`}
                type="radio"
                value={el.value}
                name={question.name}
                onChange={submitData}
                checked={data[question.name] === el.value}
                className={`w-4 h-4 text-dark-blue bg-[#FFFFFFb0] border-dark-blue border-1 cursor-pointer focus:ring-2`}
              />
              <label
                htmlFor={`${question.name}-${el.value}`}
                className={`text-sm font-interbold text-dark-blue cursor-pointer leading-tight`}
              >
                {el.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
