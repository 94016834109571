import React from "react";

import ErrorFormMessage from "./ErrorFormMessage";

export default function Textarea({
  onChange,
  name,
  label,
  desc,
  value,
  placeholder,
  theme,
  required,
  error,
  readonly,
}) {
  let themeLabelClasses = "text-dark-blue font-interbold";
  let themeClasses =
    "bg-[#FFFFFFb0] border-dark-blue placeholder:text-[#FFFFFF80] read-only:bg-gray-100";

  if (theme === "transparent") {
    themeClasses = "bg-transparent border-white placeholder:text-[#FFFFFF80]";
    themeLabelClasses = "text-white";
  }

  if (error) {
    themeClasses += " border-red-500";
    themeLabelClasses += " text-red-500";
  }

  return (
    <>
      <label
        htmlFor={name}
        className={`${themeLabelClasses} block mb-2 text-sm font-medium`}
      >
        {label} {required ? <span className="text-red-700">*</span> : ""}
      </label>
      {desc ? <div className="mb-2 text-[14px]">{desc}</div> : ""}
      <textarea
        id={name}
        name={name}
        onChange={onChange}
        className={`${themeClasses} border-2 text-sm rounded-[20px] focus:ring-1 focus:ring-blue-700 block w-full p-2.5`}
        placeholder={placeholder}
        required={required}
        readOnly={readonly}
        rows={4}
        value={value}
      />
      {error ? <ErrorFormMessage message={error} /> : null}
    </>
  );
}
