import React, { useEffect, useState } from "react";

import { downloadContract, submitContract } from "../services/ida";
import { generateRandomString, isFieldValid } from "../services/utils";
import {
  AnswersLog,
  ContractSubmit,
  ContractSubmitResponse,
  ErrorFields,
} from "../types/entities";
import Button from "./Button";
import Input from "./Input";
import LoaderScreen from "./LoaderScreen";

export default function SubmitForm({
  className,
  contactInfo,
  onSuccess,
  answersLog,
}) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [person, setPerson] = useState();

  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const [submitting, setSubmitting] = useState(false);
  const [liveValidation, setLiveValidation] = useState(false);

  useEffect(() => {
    if (!contactInfo) {
      return;
    }
    setFirstname(contactInfo.firstname);
    setLastname(contactInfo.lastname);
    setEmail(contactInfo.email);
    setPhone(contactInfo.phone);
    setPerson(contactInfo.person);
  }, []);

  const validateData = (id = "", val = "", type = "") => {
    const errors = {} as ErrorFields;

    if (id && val) {
      errors[id] = isFieldValid(val, type);
    } else {
      errors["firstname"] = isFieldValid(firstname, "text");
      errors["lastname"] = isFieldValid(lastname, "text");
      errors["email"] = isFieldValid(email, "email");
      errors["phone"] = isFieldValid(phone, "phone");
    }

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const onDownloadContract = () => {
    setLiveValidation(true);
    const res = validateData();
    if (Object.entries(res).length > 0) {
      return;
    }

    setSubmitting(true);
    const data = { ...answersLog, ...contactInfo };
    console.log("SEND", data);
    // setSubmitting(false);
    // return;

    downloadContract(data)
      .then((blobData) => {
        const blob = new Blob([blobData], { type: blobData.type });
        const url = window.URL || window.webkitURL;
        const link = url.createObjectURL(blob);

        let extension = "";
        switch (blobData.type) {
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            extension = "docx";
            break;
          case "application/pdf":
          default:
            extension = "pdf";
            break;
        }

        const a = document.createElement("a");
        a.href = link;
        a.download = `${generateRandomString()}.${extension}`;
        a.click();
      })
      .catch((error) => {
        console.log("ERR", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {submitting && <LoaderScreen />}
      <form className={className}>
        <div>
          <Input
            type="text"
            name="firstname"
            onChange={(e) => {
              setFirstname(e.target.value);
              if (liveValidation) {
                validateData("firstname", e.target.value, "text");
              }
            }}
            label="Förnamn"
            placeholder="Fyll i ditt förnamn. Minst två tecken."
            value={firstname}
            theme="transparent"
            required={true}
            error={errorFields["firstname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="text"
            name="lastname"
            onChange={(e) => {
              setLastname(e.target.value);
              if (liveValidation) {
                validateData("lastname", e.target.value, "text");
              }
            }}
            label="Efternamn"
            placeholder="Fyll i ditt efternamn. Minst två tecken."
            value={lastname}
            theme="transparent"
            required={true}
            error={errorFields["lastname"] ?? false}
          />
        </div>
        <div>
          <Input
            type="email"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
              if (liveValidation) {
                validateData("email", e.target.value, "email");
              }
            }}
            label="E-post"
            placeholder="Fyll i din e-postadress"
            value={email}
            theme="transparent"
            required={true}
            error={errorFields["email"] ?? false}
          />
        </div>
        <div>
          <Input
            type="tel"
            name="phone"
            onChange={(e) => {
              setPhone(e.target.value);
              if (liveValidation) {
                validateData("phone", e.target.value, "phone");
              }
            }}
            label="Telefonnummer"
            placeholder="Fyll i ditt telefonnummer"
            value={phone}
            theme="transparent"
            required={true}
            error={errorFields["phone"] ?? false}
          />
        </div>
        {contactInfo.person && contactInfo.person === "1" && (
          <div
            className="bottom mt-3"
            style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          >
            The document will be sent out to the trusted person as well.
          </div>
        )}
        {contactInfo.person && contactInfo.person === "2" && (
          <div
            className="bottom mt-3"
            style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
          >
            The document will be sent out to the person you are planning for as
            well.
          </div>
        )}

        <div className="mt-3 flex gap-5">
          <Button title="skicka" onClick={onSuccess} disabled={submitting} />
          {process.env.NODE_ENV !== "production" ? (
            <Button
              title="Download file"
              onClick={onDownloadContract}
              disabled={submitting}
            />
          ) : null}
        </div>
      </form>
    </>
  );
}
