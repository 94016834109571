import React, { useEffect, useLayoutEffect, useState } from "react";

import Button from "../components/Button";
import CtmPhone from "../components/CtmPhone";
import QuestionList from "../components/QuestionList";
import SubmitForm from "../components/SubmitForm";
import { getPhoneNumber } from "../services/utils";

const phoneIcon = new URL("../assets/images/phone-icon.svg", import.meta.url);
const logoFullDark = new URL(
  "../assets/images/logo-full-dark.svg",
  import.meta.url
);

export default function Index() {
  const [isStarted, setStart] = useState(false);
  const [answersLog, setAnswersLog] = useState({});
  const [contactInfo, setContactInfo] = useState({
    firstname: "John",
    lastname: "Doe",
    email: "a@vorozhbit.com",
    phone: "0123456789",
  });
  const [otherInfo, setOtherInfo] = useState({
    firstname: "Tim",
    lastname: "Tom",
    email: "asd@qwe.com",
    phone: "a@vorozhbit.com",
  });
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();
  const [submitAnswersStage, setSubmitAnswersStage] = useState(false);
  const [finalStage, setFinalStage] = useState(false);

  useEffect(() => {
    document.title = "Förberedelseformulär - Fenix";
  }, []);
  useLayoutEffect(() => {
    replacePhoneCTM();
  }, []);

  useEffect(() => {
    console.log("answersLog", answersLog);
  }, [answersLog]);

  useEffect(() => {
    console.log("contactInfo", contactInfo);
  }, [contactInfo]);

  useEffect(() => {
    console.log("otherInfo", otherInfo);
  }, [otherInfo]);

  useLayoutEffect(() => {
    replacePhoneCTM();
  }, []);

  const updateAnswersLog = (key, value) => {
    setAnswersLog((state) => {
      const log = { ...state };
      log[key] = value;
      return log;
    });
  };

  const showSubmitForm = () => {
    setSubmitAnswersStage(true);
    setStart(false);
  };

  const showFinalStage = () => {
    setSubmitAnswersStage(false);
    setStart(false);
    setFinalStage(true);
  };

  return (
    <>
      <div className="container flex flex-col flex-grow mx-auto px-3 sm:px-5">
        <div className="flex flex-col flex-grow mt-[100px] lg:mt-[200px] xl:mt-[200px] 2xl:mt-[260px]">
          {!isStarted && !submitAnswersStage && !finalStage && (
            <>
              <h1 className="font-pprightgothic text-[36px] lg:text-[56px] leading-none text-white mb-5">
                Förberedelseformulär
              </h1>
              <div className="text-white max-w-xl mb-20">
                <div className="mb-5">
                  Att förbereda sig själv och sin omgivning på vad som ska hända
                  om du blir sjuk eller går bort är ingen lätt uppgift.
                </div>
                <div className="mb-5">
                  Fenix förberedelseformulär består av 25 frågor rörande ekonomi
                  och andra praktiska önskemål som är viktiga att veta.
                </div>
                <div className="mb-5">
                  Dina svar kan vara till stor hjälp och underlätta för dina
                  anhöriga i tid som är svår ändå.
                </div>

                <Button
                  title="Skapa förberedelseformulär"
                  onClick={() => setStart(true)}
                />
              </div>
            </>
          )}

          {isStarted && !submitAnswersStage && (
            <>
              <h1 className="font-pprightgothic text-[36px] lg:text-[56px] leading-none text-white mb-5">
                Förberedelseformulär
              </h1>
              <QuestionList
                answersLog={answersLog}
                updateAnswersLog={updateAnswersLog}
                contactInfo={contactInfo}
                otherInfo={otherInfo}
                setOtherInfo={setOtherInfo}
                setContactInfo={setContactInfo}
                submitAnswers={showSubmitForm}
              />
            </>
          )}

          {!isStarted && submitAnswersStage && (
            <>
              <h1 className="font-pprightgothic text-[36px] lg:text-[56px] leading-none text-white mb-5">
                Ditt förberedelseformulär är klart!
              </h1>
              <div className="text-white mb-10 max-w-full lg:max-w-2xl">
                <p className="mb-5">
                  Tack för att du tog dig tid att besvara de här frågorna. Dina
                  svar kommer att vara till stor hjälp och underlätta för dina
                  anhöriga. Fyll i din e-postadress nedan så mailar vi dig en
                  pdf på dina svar.
                </p>
                <SubmitForm
                  contactInfo={contactInfo}
                  answersLog={answersLog}
                  className="grid gap-4 grid-cols-1 sm:grid-cols-2"
                  onSuccess={showFinalStage}
                />
              </div>
            </>
          )}

          {finalStage && (
            <>
              <h1 className="font-pprightgothic text-[36px] lg:text-[56px] leading-none text-white mb-5">
                Nu har vi mailat dig!
              </h1>
              <div className="text-white mb-10 max-w-full lg:max-w-2xl">
                <p className="mb-5">
                  Nu ska du ha fått en pdf på ditt förberedelseformulär i din
                  inkorg. Spara detta så att dina anhöriga får tillgång till det
                  om du skulle bli sjuk eller gå bort. Har du frågor eller
                  funderingar är du alltid välkommen att kontakta oss på{" "}
                  <CtmPhone /> eller maila arkiv@fenixfamily.se
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="bg-white py-5 lg:py-10">
        <div className="container mx-auto bg-white text-dark-blue flex flex-col lg:flex-row justify-between items-center px-3 sm:px-5">
          <img
            src={logoFullDark.href}
            alt="Fenix"
            className="cursor-pointer h-fit max-w-none mb-3 lg:mb-0"
            width={216}
            height={60}
            onClick={() =>
              (window.location.href = "https://fenixbegravning.se/")
            }
          />
          <div>
            <div className="text-dark-blue font-pprightgothic text-[30px] leading-none mb-1">
              Hur kan vi hjälpa dig?
            </div>
            <a
              href={`tel:${phoneFull}`}
              className="font-interbold text-medium-blue text-[30px] flex items-center gap-2 footer-phone"
            >
              <img src={phoneIcon.href} alt="" />
              <p>{phone}</p>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-dark-blue py-5 lg:py-10">
        <div className="container mx-auto text-white flex flex-col lg:flex-row items-center justify-center gap-3 lg:gap-6 text-[16px] leading-none">
          <span>FENIX FAMILY AB © 2014 - 2022</span>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixbegravning.se/wp-content/uploads/2020/10/Allmanna-villkor-2020-10-06.pdf"
            className="underline"
          >
            ALLMÄNNA VILLKOR
          </a>
          <span className="hidden lg:block">|</span>
          <a
            href="https://fenixbegravning.se/wp-content/uploads/2022/01/fenix_integritetspolicy_kund_uppdaterad_2022-01-26.pdf"
            className="underline"
          >
            INTEGRITETSPOLICY
          </a>
        </div>
      </div>
    </>
  );
}
