import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/Layout";
import IndexPage from "./pages/IndexPage";

export default function App() {
  return (
    <Routes>
      {/*<Route element={<Layout />}>*/}
      {/*  <Route path="hierarchy/:contractId" element={<HierarchyPage />} />*/}
      {/*  <Route path="contract/:contractId" element={<ContractPage />} />*/}
      {/*  <Route path="checkout/:data" element={<CheckoutPage />} />*/}
      {/*</Route>*/}
      <Route element={<Layout />}>
        <Route path="/" element={<IndexPage />} />
      </Route>
    </Routes>
  );
}
