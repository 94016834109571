import React from "react";
import { Link } from "react-router-dom";

const logo = new URL("../assets/images/logo-white.svg", import.meta.url);
const logoSign = new URL(
  "../assets/images/logo-white-sign.svg",
  import.meta.url
);

export default function Header() {
  return (
    <div
      className="absolute px-3 lg:px-8 py-5 w-full"
      style={{
        background:
          "linear-gradient(180deg, rgba(0, 39, 51, 0.7), rgba(0, 39, 51, 0))",
      }}
    >
      <Link to="/" className="flex">
        <div className="mr-4 flex">
          <img src={logo.href} alt="Fenix" width="42" height="42" />
        </div>
        <div className="flex items-center">
          <img src={logoSign.href} alt="Fenix" width="95" height="24" />
        </div>
      </Link>
    </div>
  );
}
