import React from "react";

import ErrorFormMessage from "./ErrorFormMessage";

type Props = {
  type: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  desc?: string;
  value?: string | number;
  placeholder?: string;
  theme?: string;
  required?: boolean;
  readonly?: boolean;
  error?: React.ReactElement | boolean;
};

export default function Input({
  type,
  onChange,
  name,
  label,
  desc,
  value,
  placeholder,
  theme,
  required,
  error,
  readonly,
}: Props) {
  let themeLabelClasses = "text-dark-blue font-interbold";
  let themeClasses =
    "bg-[#FFFFFFb0] border-dark-blue placeholder:text-[#FFFFFF80] read-only:bg-gray-100";

  if (theme === "transparent") {
    themeClasses = "bg-transparent border-white placeholder:text-[#FFFFFF80]";
    themeLabelClasses = "text-white";
  }

  if (error) {
    themeClasses += " border-red-500";
    themeLabelClasses += " text-red-500";
  }

  return (
    <>
      <label
        htmlFor={name}
        className={`${themeLabelClasses} block mb-2 text-sm font-medium`}
      >
        {label} {required ? <span className="text-red-700">*</span> : ""}
      </label>
      {desc ? <div className="mb-2 text-[14px]">{desc}</div> : ""}
      <input
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        className={`${themeClasses} border-2 text-sm rounded-full focus:ring-1 focus:ring-blue-700 block w-full p-2.5`}
        placeholder={placeholder}
        value={value}
        required={required}
        readOnly={readonly}
      />
      {error ? <ErrorFormMessage message={error} /> : null}
    </>
  );
}
