import React, { useEffect, useState } from "react";

import Button from "./Button";
import Input from "./Input";

export default function InputSet({ question, onChange, data }) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    console.log("index", index);
  }, [index]);

  useEffect(() => {
    if (data[question.name] && Object.values(data[question.name]).length > 0) {
      setIndex(Object.values(data[question.name]).length);
    }
  }, [data]);

  const setData = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;

    const dt = { ...data };
    if (!dt[question.name]) {
      dt[question.name] = [];
    }
    if (!dt[question.name][index]) {
      dt[question.name][index] = {};
    }
    dt[question.name][index][name] = value;
    onChange(dt);
  };

  const removeRow = (index) => {
    setIndex((prevState) => {
      const newInd = prevState - 1;
      return newInd < 0 ? 0 : newInd;
    });

    const dt = { ...data };

    if (!dt[question.name] || !dt[question.name][index]) {
      return;
    }

    dt[question.name] = dt[question.name].reduce(
      (acc, currentValue, currentIndex) => {
        if (currentIndex !== index) {
          return [...acc, currentValue];
        }
        return acc;
      },
      []
    );

    onChange(dt);
  };

  const addRow = () => {
    setIndex((prevState) => prevState + 1);
  };

  const renderSet = () => {
    const out = [];
    for (let i = 0; i < index; i++) {
      out.push(
        <div key={i} className="flex gap-5 items-center mb-3">
          {question.children.map((elm) => {
            const val =
              data[question.name] &&
              data[question.name][i] &&
              data[question.name][i][elm.name]
                ? data[question.name][i][elm.name]
                : "";
            return (
              <div key={elm.id} className="flex flex-col">
                <Input
                  type="text"
                  name={elm.name}
                  onChange={(e) => setData(e, i)}
                  label={elm.title}
                  value={val}
                />
              </div>
            );
          })}
          <div className="self-end mb-2">
            <Button
              title="Remove"
              theme="dark"
              size="small"
              onClick={() => removeRow(i)}
            />
          </div>
        </div>
      );
    }
    return out;
  };
  return (
    <div>
      {renderSet()}
      <Button
        title="Add person"
        theme="dark"
        size="small"
        cssCLasses="mt-3"
        onClick={() => addRow()}
      />
    </div>
  );
}
