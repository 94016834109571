import React from "react";

export default function ProgressSections({
  sections,
  activeSection,
  setActive,
}) {
  if (!sections) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-stretch justify-between w-full bg-dark-blue rounded-xl gap-[2px] lg:flex-row"
      style={{ boxShadow: "0px 18px 21px 11px rgba(0, 0, 0, 0.2)" }}
    >
      {sections.map((section) => {
        const active = section.id === activeSection;
        const completed = section.completed === true;

        return (
          <div
            className={`group flex items-center lg:justify-center flex-grow px-4 py-3 lg:py-5 first:rounded-t-xl last:rounded-b-xl lg:first:rounded-tr-none lg:first:rounded-l-xl lg:last:rounded-r-xl lg:last:rounded-bl-none cursor-pointer hover:bg-white ${
              active ? "!bg-white" : ""
            } ${completed ? "bg-light-yellow" : ""}`}
            key={section.id}
            onClick={() => setActive(section.id)}
          >
            <div
              className={`w-[18px] h-[18px] flex items-center justify-center shrink-0 leading-none rounded-xl bg-white text-dark-blue mr-3 font-interbold text-[10px] group-hover:bg-dark-blue group-hover:text-white ${
                active || completed ? "bg-dark-blue text-white" : ""
              }`}
            >
              {section.id}
            </div>
            <div
              className={`text-[14px] leading-tight text-white font-interbold group-hover:text-dark-blue ${
                active || completed ? "!text-dark-blue" : ""
              }`}
            >
              {section.title}
            </div>
          </div>
        );
      })}
    </div>
  );
}
