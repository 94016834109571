import parse from "html-react-parser";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { generateRandomString } from "../services/utils";
import { AnswerData, QuestionItem, VariablesList } from "../types/entities";
import Button from "./Button";
import Radio from "./fields/Radio";
import HelpMessage from "./HelpMessage";
import Input from "./Input";
import InputSet from "./InputSet";
import Textarea from "./Textarea";

const arrowIconDark = new URL(
  "../assets/images/question-arrow-dark.svg",
  import.meta.url
);
const arrowIcon = new URL(
  "../assets/images/question-arrow.svg",
  import.meta.url
);

export default function Question({
  question,
  submitQuestionData,
  answers,
  openOnLoad,
  setQuestionCompleted,
  modalMode,
  modalClose,
  index,
}) {
  const [expand, setExpand] = useState(openOnLoad);
  const [data, setData] = useState({});
  const [completed, setCompleted] = useState(false);
  const [showNested, setShowNested] = useState(false);
  const [height, setHeight] = useState(0 as number);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const dt = {};
    if (answers[question.name] !== undefined) {
      dt[question.name] = answers[question.name];
    }

    if (question.children && question.children.length > 0) {
      question.children.map((el) => {
        if (answers[el.name] !== undefined) {
          dt[el.name] = answers[el.name];
        }
      });
    }

    if (Object.keys(dt).length > 0) {
      setData(dt);
    }

    checkShowNested();
  }, []);

  useEffect(() => {
    checkShowNested();
  }, [data]);

  useLayoutEffect(() => {
    if (ref.current && !modalMode) {
      setHeight(ref.current.clientHeight);
    }
  });

  useLayoutEffect(() => {
    if (question.completed) {
      setCompleted(question.completed);
    }
  }, []);

  const checkShowNested = () => {
    const qValue = data[question.name];
    if (!question.options) {
      return;
    }

    const hasNested = question.options.filter((el) => {
      return el.value === qValue && el.nested;
    });

    if (hasNested.length === 0) {
      setShowNested(false);
    }

    if (hasNested.length > 0 && !showNested) {
      setShowNested(true);
    }
  };

  const defaultClasses = ["overflow-hidden", "transition-[max-height]"];

  const transitionStyles = (st: string) => {
    const styles: { [k: string]: { maxHeight: string | number } } = {
      // entering: { maxHeight: `${height > 0 ? `${height}px` : "initial"}` },
      // entered: { maxHeight: `${height > 0 ? `${height}px` : "initial"}` },
      exiting: { maxHeight: 0 },
      exited: { maxHeight: 0 },
    };
    return styles[st] ?? null;
  };

  const submitData = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const dt = { ...data };
    dt[name] = value;
    setData(dt);
  };

  const renderFields = (q, showTitles = false) => {
    const type = q.field;
    if (type === "radio") {
      return <Radio question={q} data={data} setData={setData} key={q.id} />;
    }
    if (type === "input") {
      return (
        <div key={q.id} className="mb-6 px-0.5">
          <div className="max-w-[400px] mb-3 sm:mb-5">
            <Input
              type="text"
              name={q.name}
              desc={q.desc}
              onChange={submitData}
              label={showTitles ? q.title : ""}
              value={data[q.name]}
              key={q.id}
            />
          </div>
        </div>
      );
    }
    if (type === "textarea") {
      return (
        <div key={q.id} className="mb-6 px-0.5">
          <div className="max-w-[400px] mb-3 sm:mb-5">
            <Textarea
              name={q.name}
              desc={q.desc}
              onChange={submitData}
              label={showTitles ? q.title : ""}
              value={data[q.name]}
              key={q.id}
            />
          </div>
        </div>
      );
    }
    if (type === "set") {
      return (
        <div key={q.id} className="mb-6 px-0.5">
          <div className="max-w-[600px] mb-3 sm:mb-5">
            <InputSet question={q} data={data} onChange={setData} />{" "}
          </div>
        </div>
      );
    }

    return null;
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const clickNext = () => {
    setExpand(false);
    setQuestionCompleted(question);

    const dt = { ...data };

    if (question.options) {
      const hasNested = question.options.filter((el) => {
        return el.value === data[question.name] && el.nested;
      });

      if (hasNested.length === 0 && data[question.name]) {
        question.children?.map((el) => {
          if (dt[el.name]) {
            dt[el.name] = "";
          }
        });
      }
    }

    for (const [key, value] of Object.entries(dt)) {
      submitQuestionData(key, value);
    }
    if (modalMode) {
      modalClose();
    }
  };

  let bgColor = "bg-white";
  if (completed) {
    bgColor = "bg-light-yellow";
  }

  if (modalMode) {
    bgColor = "!p-0 !pt-2";
  }

  return (
    <div
      className={`relative shadow-default rounded-xl px-3 py-4 md:px-7 md:py-6 ${bgColor}`}
    >
      <div className="flex flex-row items-center flex-1 justify-between">
        <h1
          className={`font-pprightgothic text-[24px] sm:text-[34px] leading-none text-dark-blue max-w-full lg:max-w-3xl ${
            !modalMode && "cursor-pointer"
          }`}
          onClick={() => {
            if (!modalMode) {
              toggleExpand();
            }
          }}
        >
          {question.title}
        </h1>
        {!modalMode && (
          <div
            className={`p-1 transition hover:cursor-pointer ${
              expand ? "rotate-0" : "rotate-180"
            }`}
            onClick={toggleExpand}
          >
            <img src={arrowIconDark} width="25" height="25" />
          </div>
        )}
      </div>
      <CSSTransition in={expand} timeout={150} nodeRef={ref}>
        {(state) => (
          <div
            className={defaultClasses.join(" ")}
            style={{ ...transitionStyles(state) }}
            ref={ref}
          >
            <div className="pt-3 max-w-full flex flex-col lg:flex-row justify-between gap-5">
              <div className="w-full lg:max-w-2xl">
                {renderFields(question)}
                {showNested
                  ? question.children?.map((el) => {
                      return renderFields(el, true);
                    })
                  : null}
              </div>
              {(index + 1) % 2 === 1 ? (
                <div className="w-full md:max-w-[300px]">
                  <HelpMessage type={`section${question.section}`} />
                </div>
              ) : null}
            </div>
            <Button
              theme="dark"
              title={question.completed ? "Update" : "Nästa"}
              onClick={clickNext}
            />
          </div>
        )}
      </CSSTransition>
    </div>
  );
}
