import React from "react";

import CtmPhone from "./CtmPhone";

export default function HelpMessage({ type = "" }) {
  const texts = [
    <span key={1}>
      Behöver du hjälp med en fråga? Kontakta oss gärna på{" "}
      <a className="underline" href="mailto:kund@fenixfamily.se">
        kund@fenixfamily.se
      </a>{" "}
      eller ring <CtmPhone />.
    </span>,
    <span key={2}>
      Du är alltid välkommen att höra av dig till någon av våra rådgivare om det
      är något du undrar över.
    </span>,
    <span key={3}>
      Hos oss finns duktiga jurister som kan svara på alla dina frågor och
      funderingar. Maila gärna till{" "}
      <a className="underline" href="mailto:kund@fenixfamily.se">
        kund@fenixfamily.se
      </a>{" "}
      eller ring <CtmPhone />.
    </span>,
    <span key={4}>
      Behöver du råd och stöttning av en jurist eller rådgivare är det bara att
      höra av dig till oss på Fenix.
    </span>,
  ];

  const HelpEmail = () => {
    return (
      <a className="underline" href="mailto:arkiv@fenixfamily.se">
        arkiv@fenixfamily.se
      </a>
    );
  };

  const getText = () => {
    switch (type) {
      case "contact":
        return (
          <span>
            Har du frågor eller funderingar är du alltid välkommen att kontakta
            oss på <CtmPhone /> eller maila <HelpEmail />
          </span>
        );
      case "section4":
        return (
          <span>
            Har du givit bort något som ska eller kan komma att ses som förskott
            på arv? Kontakta oss gärna om du behöver juridisk hjälp med gåvobrev
            eller annan dokumentation så att allt blir rätt och riktigt. Du når
            oss på <CtmPhone /> eller genom att maila <HelpEmail />
          </span>
        );
      default:
        return texts[Math.floor(Math.random() * texts.length)];
    }
  };
  return (
    <div className="border border-dark-blue rounded-xl p-3 lg:p-4 font-interlight text-[14px] italic">
      {getText()}
    </div>
  );
}
