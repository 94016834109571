import React, { useLayoutEffect } from "react";

import { getPhoneNumber } from "../services/utils";

export default function CtmPhone() {
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();

  useLayoutEffect(() => {
    replacePhoneCTM();
  }, []);

  return (
    <a className="underline" href={`tel:${phoneFull}`}>
      {phone}
    </a>
  );
}
