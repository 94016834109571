const apiUrl: string = process.env.IDA_API_URL;
import * as store from "store";

import {
  ContractSet,
  ContractSubmit,
  ContractSubmitResponse,
} from "../types/entities";

export async function getContract(id: string): Promise<ContractSet> {
  const response = await fetch(`${apiUrl}/public/contract/template/${id}`, {
    method: "GET",
  }).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as ContractSet;
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function downloadContract(data): Promise<Blob> {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  formData.append("download", 1);

  const response = await fetch(
    "https://feature-publicweb-390-se-preplanning.bubenweb.dev/wp-content/themes/fenix4/page-online-form-api.php",
    {
      method: "POST",
      cache: "no-cache",
      // mode: "no-cors",
      // mode: "cors",
      body: formData,
      headers: {
        Authorization: "Basic Zm54NDpmbng0",
        // "Access-Control-Allow-Origin": "http://localhost:1234",
        // "Access-Control-Allow-Methods":
        //   "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers":
        //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      },
    }
  ).catch((error: string) => {
    throw Error(error);
  });
  if (response.ok) {
    return response.blob();
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}

export async function submitContract(
  data: ContractSubmit
): Promise<ContractSubmitResponse> {
  const utm = store.get("fnxUtm");
  const response = await fetch(`${apiUrl}/public/contract?test=false`, {
    method: "POST",
    body: JSON.stringify({ ...data, ...utm }),
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((error: string) => {
    throw Error(error);
  });

  if (response.ok) {
    const data = (await response.json()) as { paymentClientSecret: string };
    return data ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
}
