let qid = 0;
const getNextId = () => {
  qid += 1;
  return qid;
};

export const questionsData = () => {
  return [
    {
      id: getNextId(),
      section: 2,
      field: "radio",
      type: null,
      title: "Vilken sorts begravningsceremoni önskar du?",
      name: "ceremony_type",
      options: [
        { value: "Kyrklig", label: "Kyrklig" },
        { value: "Borgerlig", label: "Borgerlig" },
        { value: "Direktkremation", label: "Direktkremation" },
        { value: "Annan", label: "Annan", nested: true, skipSummary: true },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "",
          name: "ceremony_type_other",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 2,
      field: "input",
      type: "text",
      title: "Var ska ceremonin hållas?",
      desc: "(val av kyrka eller ceremonilokal)",
      name: "ceremony_venue",
      completed: false,
    },
    {
      id: getNextId(),
      section: 2,
      field: "input",
      type: "text",
      title: "Önskad begravningsplats eller kyrkogård",
      name: "bury_place",
      completed: false,
    },
    {
      id: getNextId(),
      section: 2,
      field: "radio",
      type: null,
      title: "Typ av gravsättning",
      name: "bury_type",
      options: [
        { value: "Gravplats för kista", label: "Gravplats för kista" },
        { value: "Gravplats för urna", label: "Gravplats för urna" },
        { value: "Minneslund för aska", label: "Minneslund för aska" },
        {
          value: "Askspridning över vatten",
          label: "Askspridning över vatten",
        },
        { value: "Annan", label: "Annan", nested: true, skipSummary: true },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "",
          name: "bury_type_other",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 2,
      field: "input",
      type: "text",
      title: "Jag vill om möjligt bli begraven tillsammans med",
      name: "bury_with",
      completed: false,
    },
    {
      id: getNextId(),
      section: 2,
      field: "textarea",
      type: null,
      title: "Övriga önskemål i fritext",
      desc: "(t.ex. om musik, blommor, kista, samling efteråt, etc.)",
      name: "other_wishes",
      completed: false,
    },

    {
      id: getNextId(),
      section: 3,
      field: "radio",
      type: null,
      title:
        "Har du skrivit en framtidsfullmakt som anger vem eller vilka ska företräda dig om du inte längre kan göra det själv?",
      name: "prospectus",
      options: [
        { value: "1", label: "Ja", nested: true },
        { value: "0", label: "Hej" },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Var finns originalhandlingen?",
          name: "prospectus_document",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 3,
      field: "radio",
      type: null,
      title: "Vill du donera dina organ?",
      desc: "Information om donation ska också sparas på livsviktigt.se",
      name: "donate_organs",
      options: [
        { value: "1", label: "Ja" },
        { value: "0", label: "Hej" },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 3,
      field: "textarea",
      type: null,
      title:
        "Har du andra specifika önskemål om medicinsk vård inför en situation då du inte själv kan fatta beslut?",
      name: "medical_care_request",
      completed: false,
    },

    {
      id: getNextId(),
      section: 4,
      field: "radio",
      type: null,
      title: "Har du en livförsäkring?",
      name: "life_insurance",
      options: [
        { value: "1", label: "Ja", nested: true },
        { value: "0", label: "Hej" },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Hos vilket/vilka försäkringsbolag?",
          name: "life_insurance_company",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "radio",
      type: null,
      title: "Har du skrivit ett testamente?",
      name: "will",
      options: [
        { value: "1", label: "Ja", nested: true },
        { value: "0", label: "Hej" },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Var finns originalhandlingen?",
          name: "will_document",
        },
        {
          id: getNextId(),
          field: "textarea",
          type: null,
          title:
            "Om du fick hjälp av en jurist, ange namn och kontaktuppgift nedan",
          name: "will_lawyer",
        },
        {
          id: getNextId(),
          field: "textarea",
          type: null,
          title:
            "Har du i testamentet angivit en testamentesexekutor som ska förvalta dödsboet, ordna med bouppteckning eller planera begravningen?",
          desc: "Ange i så fall namn och kontaktuppgift nedan.",
          name: "will_finance",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "input",
      type: "text",
      title: "Vad har du för bank?",
      desc: "Ange endast namnet, kontouppgifter behövs ej.",
      name: "bank",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Har du sparande och försäkringar hos några andra företag?",
      desc: "Ange företagens namn nedan.",
      name: "other_companies",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Har du kassaskåp eller bankfack?",
      desc: "Ange var de finns samt om någon annan har tillgång, kan koden eller har nyckel. Ange inte något som kan riskera säkerheten för dina tillgångar idag.",
      name: "safe",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Äger du några fasta tillgångar?",
      desc: "Beskriv nedan och ange gärna ägarandel (fastigheter, bostadsrätter, mm.).",
      name: "assets",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Finns det fysiska pantbrev på din fastighet? Var finns dessa?",
      name: "mortgage",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Övriga tillgångar",
      desc: "(bil, båt, husvagn, mm.)",
      name: "other_assets",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Är du företagare?",
      desc: "Ange kontaktperson som kan hjälpa dina anhöriga med information (t.ex. revisor)",
      name: "entrepreneur_info",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "radio",
      type: null,
      title:
        "Finns det någon enskild egendom eller äktenskapsförord som påverkar din eller din makes egendom?",
      name: "individual_property",
      options: [
        { value: "1", label: "Ja", nested: true },
        { value: "0", label: "Hej" },
      ],
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Var finns originalhandlingarna?",
          name: "individual_property_document",
        },
        {
          id: getNextId(),
          field: "textarea",
          type: null,
          title: "Kommentar till innehållet",
          name: "individual_property_comment",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title: "Har du några skulder?",
      desc: "Ange kreditinstitut nedan.",
      name: "debts",
      completed: false,
    },
    {
      id: getNextId(),
      section: 4,
      field: "textarea",
      type: null,
      title:
        "Övrig information om din ekonomiska situation som kan vara bra för efterlevande att känna till",
      name: "financial_info",
      completed: false,
    },

    {
      id: getNextId(),
      section: 5,
      field: "set",
      type: null,
      title:
        "Vem ska kontaktas och informeras om att du har gått bort utöver närmaste familj och vänner?",
      desc: "Ange de du tror att dina efterlevande inte självklart kontaktar.",
      name: "contact_persons",
      children: [
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Namn",
          name: "name",
        },
        {
          id: getNextId(),
          field: "input",
          type: "text",
          title: "Tel",
          name: "tel",
        },
      ],
      completed: false,
    },
    {
      id: getNextId(),
      section: 5,
      field: "textarea",
      type: "",
      title: "Vem ska ta hand om dina husdjur?",
      name: "care_of_pets",
      completed: false,
    },
    {
      id: getNextId(),
      section: 5,
      field: "textarea",
      type: "",
      title: "Vem har nyckel till din bostad?",
      name: "keys_from_home",
      completed: false,
    },
    {
      id: getNextId(),
      section: 5,
      field: "textarea",
      type: "",
      title: "Vad har du för bil och var brukar du parkera?",
      name: "car_info",
      completed: false,
    },
    {
      id: getNextId(),
      section: 5,
      field: "textarea",
      type: "",
      title:
        "Vilka abonnemang, prenumerationer eller medlemskap behöver avslutas?",
      name: "memberships_info",
      completed: false,
    },
  ];
};

export const sectionsData = () => {
  return [
    {
      id: 1,
      title: "Dina uppgifter",
      desc: "",
      completed: false,
    },
    {
      id: 2,
      title: "Önskemål om begravning",
      desc: "Fyll i så mycket du kan och vill och överlåt resten till dina anhöriga.",
      completed: false,
    },

    {
      id: 3,
      title: "Ålderdom och vård i livets slut",
      desc: "Fyll i så mycket du kan och vill och överlåt resten till dina anhöriga.",
      completed: false,
    },
    {
      id: 4,
      title: "Ekonomisk information",
      desc: "Ange endast sådant som underlättar för dina anhöriga att hitta tillgångar och skulder, t.ex. företagens namn eller vem som kan lämna mer information. Ange inte något som kan riskera säkerheten för dina tillgångar (t.ex. lösenord, koder, var nycklar finns, etc.). Har du givit bort något som ska eller kan komma att ses som förskott på arv? Kontakta gärna vår jurist för att få hjälp att undvika osämja bland dina arvtagare.",
      completed: false,
    },
    {
      id: 5,
      title: "Övriga praktiska frågor",
      desc: "",
      completed: false,
    },
    {
      id: 6,
      title: "Summary",
      desc: "",
      completed: false,
    },
  ];
};
