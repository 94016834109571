import React from "react";
import { Trans } from "react-i18next";

export const generateRandomString = (length = 5): string => {
  return Math.random()
    .toString(36)
    .substr(2, length)
    .split("")
    .map((c) => (Math.random() < 0.5 ? c.toUpperCase() : c))
    .join("");
};

export const isFieldValid = (
  value: string,
  type = "text"
): React.ReactElement | boolean => {
  const personNumberRegex =
    "^(?:(\\d{8}\\-\\d{4}){1}|(\\d{12}){1}|(\\d{10}){1}|(\\d{6}\\-\\d{3}){1})$";
  const emailRegex = "^[a-z0-9._\\-\\+]{1,64}@[a-z0-9.]{1,64}$";

  switch (type) {
    case "range":
      if (value === "") {
        return <Trans>error.input.empty</Trans>;
      }
      break;
    case "radio":
      if (value === undefined) {
        return <Trans>error.radio</Trans>;
      }
      break;
    case "personnummer":
      if (value.match(personNumberRegex) === null) {
        return <Trans>error.personnummer</Trans>;
      }
      break;
    case "email":
      if (value.match(emailRegex) === null) {
        return <Trans>error.email</Trans>;
      }
      break;
    case "phone":
      if (value === "" || value.length < 6) {
        return <Trans>error.phone</Trans>;
      }
      break;
    case "text":
    default:
      if (value === "") {
        return <Trans>error.input.empty</Trans>;
      } else if (value.length < 2) {
        return <Trans>error.input.short</Trans>;
      }
  }

  return true;
};

export const getPhoneNumber = (): [string, string, () => void] => {
  const phone = "020-82 82 82";
  const phoneFull = phone
    .replaceAll("020", "+4620")
    .replaceAll(" ", "")
    .replaceAll("-", "");
  return [
    phone,
    phoneFull,
    () => {
      if (window.__ctm) {
        window.__ctm.main.runNow();
      }
    },
  ];
};

export function encodeBase64(data: object | string | number): string {
  return window.btoa(JSON.stringify(data));
}

export function decodeBase64(data: string): any {
  const base64ToString = Buffer.from(data, "base64").toString();
  return JSON.parse(base64ToString);
}

export function formatPrice(value: string | number): string {
  const v = typeof value === "string" ? parseInt(value, 10) : value;

  if (v === 0) {
    return v.toString();
  }
  const p = Math.round(v / 100).toString();

  const len = p.length;
  const div = Math.floor(len / 3);

  const parts = [];
  for (let i = 1; i <= div + 1; i++) {
    let start = len - i * 3;
    let l = 3;
    if (start < 0) {
      l = l + start;
      start = 0;
    }
    parts.push(p.substr(start, l));
  }

  return parts.reverse().join(" ").trim();
}

export function dataDeepCopy(data: any): any {
  return JSON.parse(JSON.stringify(data));
}
